// eslint-disable-next-line no-unused-vars
export default function loadForm() {
  try {
    var f = document.createElement("iframe");
    f.src = 'https://forms.zohopublic.com/thetrashgurus/form/TestForm/formperma/im8P3-d7g9Xj8Rzf-xsodI523isY6xEBLMfqRZhndVQ?zf_rszfm=1';
    f.style.border = "none";
    f.style.height = "448px";
    f.style.width = "100%";
    f.style.transition = "all 0.5s ease";
    var d = document.getElementById("zf_div_oqeSkQCxdTffjZe99pxRoynhveMeo_06rGqj14VNONo");
    if (d) {
      d.appendChild(f);
      window.addEventListener('message', function (event) {
        var evntData = event.data;
        if (evntData && evntData.constructor === String) {
          var zf_ifrm_data = evntData.split("|");
          if (zf_ifrm_data.length === 2) {
            var zf_perma = zf_ifrm_data[0];
            var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
            var div = document.getElementById("zf_div_im8P3-d7g9Xj8Rzf-xsodI523isY6xEBLMfqRZhndVQ")
            if (div) {
              var iframe = div.getElementsByTagName("iframe")[0];
              if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
                var prevIframeHeight = iframe.style.height;
                if (prevIframeHeight !== zf_ifrm_ht_nw) {
                  iframe.style.height = zf_ifrm_ht_nw;
                }
              }
            }
          }
        }
      }, false);
    }

  } catch (e) { }
};