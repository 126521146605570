import { CCol, CContainer, CRow } from "@coreui/react";
import React, { useEffect } from "react";
import loadForm from "./loadForm.js";

export default function ScrapperSignUp() {
  useEffect(() => {
    document.title = "Scrapper Sign Up | Trash Gurus";
    loadForm();
  }, []);


  return (
    <CContainer>
      <CRow >
        <CCol lg="6" md="8" className="mx-auto my-3">
          <div id="zf_div_im8P3-d7g9Xj8Rzf-xsodI523isY6xEBLMfqRZhndVQ" />
        </CCol>
      </CRow>

    </CContainer>


  );
}