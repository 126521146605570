import React, { useEffect, useState } from "react";
import {
  CButton,
  CContainer,
  CNavbar,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
} from '@coreui/react'
import logo from "../../assets/img/svg/logo-dark-green.svg"
import { Link } from "react-router-dom";

export default function Navbar() {
  const [y, setY] = useState(window.scrollY);
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <CNavbar expand="lg" colorScheme="light" className="bg-light" placement="sticky-top">
        <CContainer fluid>
          <CNavbarBrand href="#">
            <img src={logo} alt="" />
          </CNavbarBrand>
          <CNavbarToggler onClick={() => setVisible(!visible)} />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav className="ms-auto">
              <CButton color="dark" variant="ghost">Sign In</CButton>
              <Link to="scrappers">
                <CButton
                  color="tg-dark">
                  Become A Scrapper
                </CButton>
              </Link>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
}


